// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-en-js": () => import("./../src/pages/about-us.en.js" /* webpackChunkName: "component---src-pages-about-us-en-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-articles-en-js": () => import("./../src/pages/articles.en.js" /* webpackChunkName: "component---src-pages-articles-en-js" */),
  "component---src-pages-articles-js": () => import("./../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-contact-us-en-js": () => import("./../src/pages/contact-us.en.js" /* webpackChunkName: "component---src-pages-contact-us-en-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-how-to-browse-articles-en-js": () => import("./../src/pages/how-to-browse-articles.en.js" /* webpackChunkName: "component---src-pages-how-to-browse-articles-en-js" */),
  "component---src-pages-how-to-browse-articles-js": () => import("./../src/pages/how-to-browse-articles.js" /* webpackChunkName: "component---src-pages-how-to-browse-articles-js" */),
  "component---src-pages-how-to-use-archive-en-js": () => import("./../src/pages/how-to-use-archive.en.js" /* webpackChunkName: "component---src-pages-how-to-use-archive-en-js" */),
  "component---src-pages-how-to-use-archive-js": () => import("./../src/pages/how-to-use-archive.js" /* webpackChunkName: "component---src-pages-how-to-use-archive-js" */),
  "component---src-pages-index-en-js": () => import("./../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maps-en-js": () => import("./../src/pages/maps.en.js" /* webpackChunkName: "component---src-pages-maps-en-js" */),
  "component---src-pages-maps-js": () => import("./../src/pages/maps.js" /* webpackChunkName: "component---src-pages-maps-js" */),
  "component---src-pages-multimedia-en-js": () => import("./../src/pages/multimedia.en.js" /* webpackChunkName: "component---src-pages-multimedia-en-js" */),
  "component---src-pages-multimedia-js": () => import("./../src/pages/multimedia.js" /* webpackChunkName: "component---src-pages-multimedia-js" */),
  "component---src-pages-privacy-policy-en-js": () => import("./../src/pages/privacy-policy.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-en-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-long-singular-js": () => import("./../src/templates/long-singular.js" /* webpackChunkName: "component---src-templates-long-singular-js" */),
  "component---src-templates-multimedia-section-js": () => import("./../src/templates/multimedia-section.js" /* webpackChunkName: "component---src-templates-multimedia-section-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

